import React from "react"
import BlockWrapper from "../technical/BlockWrapper"
import Richtext from "@yumgmbh/gatsby-theme-yum-components/src/components/atoms/Richtext"
import Figure from "../molecules/Figure"
import HeadlineEnhanced from "../molecules/HeadlineEnhanced"
import ButtonGroup from "../molecules/ButtonGroup"
import TextReveal from "../reveals/TextReveal"

const TextImageBlock = ({ block }) => {
  let imageWidth = ""
  let textWidth = ""

  // if (block.image_size === "big") {
  //   imageWidth = "lg:w-2/3"
  //   textWidth = "lg:w-1/3"
  // } else if (block.image_size === "small") {
  //   imageWidth = "lg:w-1/3"
  //   textWidth = "lg:w-2/3"
  // } else if (block.image_size === "extra-small") {
  //   imageWidth = "lg:w-1/6"
  //   textWidth = "lg:w-5/6"
  // } else {
  //   imageWidth = "lg:w-1/2"
  //   textWidth = "lg:w-1/2"
  // }

  if (block.image_size === "small") {
    imageWidth = "md:w-2/3"
  } else if (block.image_size === "extra-small") {
    imageWidth = "md:w-1/2"
  } else {
    imageWidth = ""
  }

  return (
    <BlockWrapper block={block} showHeadline="false">
      <div className="flex flex-row flex-wrap -mx-grid">
        <div className={`relative w-full lg:w-1/2 px-grid`}>
          {block.image?.filename && (
            <Figure
              image={block.image}
              caption={block.caption}
              aspectRatio={block.aspect_ratio}
              className={`mx-auto ${imageWidth}`}
            />
          )}
        </div>
        <div
          className={`w-full mb-4 lg:w-1/2 px-grid ${
            block.text_position === "left" ? "lg:order-first" : ""
          }`}
        >
          <HeadlineEnhanced
            kicker={block.kicker}
            headline={block.headline}
            headlineLevel={block.headline_level}
            subheadline={block.subheadline}
            className="pt-8 lg:pt-0"
          />
          <TextReveal>
            <Richtext
              text={block.text}
              className={
                block.headline?.length > 0 || block.subheadline?.length > 0
                  ? "pt-8"
                  : ""
              }
            />
          </TextReveal>
          <div className="w-full mt-8 lg:w-1/2">
            <ButtonGroup className="lg:mt-1" buttons={block.buttons} />
          </div>
        </div>
      </div>
    </BlockWrapper>
  )
}

export default TextImageBlock
